<template>
    <LayoutHeader/>
    <main class="main-content">
        <h1 class="main-content__heading">404 - Looks like you’re lost.</h1>
        <p class="main-content__subtitle">Maybe this page used to exist or you just spelled something wrong.</p>
        <p class="text-block__text text-block__text--quarter-margin text-block__text--center">
            <router-link to="/" class="text-block__inline-link">Take me home</router-link>
        </p>
    </main>
</template>

<script>
import LayoutHeader from '@/app/common/pkg/LayoutHeader.vue'

export default {
    name: 'NotFound',
    components: {
        LayoutHeader
    }
}
</script>

<style lang="scss">

</style>
