import {createRouter, createWebHistory} from 'vue-router'
import NotFound from '@/app/pages/notFound/NotFound.vue'
import MainPage from '@/app/pages/mainPage/MainPage.vue'
import TermOfUse from '@/app/pages/termOfUse/TermOfUse.vue'
import PrivacyPolicy from '@/app/pages/privacyPolicy/PrivacyPolicy.vue'

const routes = [
    {
        path: '/',
        component: MainPage,
        name: 'main-page'
    },
    {
        path: '/terms-of-use/',
        component: TermOfUse,
        name: 'term-of-use'
    },
    {
        path: '/privacy-policy/',
        component: PrivacyPolicy,
        name: 'privacy-policy'
    },
    {
        path: '/:pathMatch(.*)*',
        component: NotFound,
        name: 'notFound'
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if ((to.hash !== null) && (to.hash.length > 0)) {
            return {
                el: to.hash,
                behavior: 'smooth'
            }
        }

        return {top: 0}
    }
})

export default router
