<template>
    <LayoutHeader />
    <main class="main-content">
        <h1 class="main-content__heading  grad-text">Privacy Policy</h1>
        <section class="main-content__text-block text-block">
            <p class="text-block__text">
                Last Modified: February 19, 2024
            </p>
            <p class="text-block__text text-block__text--double-margin">
                TOO GSL Aiti ("TOO GSL Aiti", "us", "we", or "our") operates websites, games and mobile applications (the "Service").
            </p>
            <p class="text-block__text text-block__text--double-margin">
                This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.
            </p>
            <p class="text-block__text text-block__text--quarter-margin text-block__text--bold grad-text">
                Definitions
            </p>
            <p class="text-block__text text-block__text--double-margin text-block__text--bold-small">
                Service
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Service means websites and mobile applications operated by TOO GSL Aiti
            </p>
            <p class="text-block__text text-block__text--double-margin text-block__text--bold-small">
                Personal Data
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).
            </p>
            <p class="text-block__text text-block__text--double-margin text-block__text--bold-small">
                Usage Data
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
            </p>
            <p class="text-block__text text-block__text--double-margin text-block__text--bold-small">
                Cookies
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Cookies are small pieces of data stored on your device (computer or mobile device).
            </p>
            <p class="text-block__text text-block__text--double-margin text-block__text--bold-small">
                Data Controller
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Data Controller means the natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in
                which any personal information are, or are to be, processed.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                For the purpose of this Privacy Policy, we are a Data Controller of your Personal Data.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Data Processors (or Service Providers)
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Data Processor (or Service Provider) means any natural or legal person who processes the data on behalf of the Data Controller.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                We may use the services of various Service Providers in order to process your data more effectively.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Data Subject (or User)
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Data Subject is any living individual who is using our Service and is the subject of Personal Data.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Information Collection And Use
            </p>
            <p class="text-block__text text-block__text--double-margin">
                We collect several different types of information for various purposes to provide and improve our Service to you.
            </p>
            <p class="text-block__text text-block__text--double-margin text-block__text--bold-small">
                Types of Data Collected
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Personal Data
            </p>
            <p class="text-block__text text-block__text--double-margin">
                While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data").
                Personally identifiable information may include, but is not limited to:
            </p>
            <ul class="text-block__list">
                <li class="text-block__item">
                    <p class="text-block__text text-block__text--single-bottom-margin">Email address</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text text-block__text--single-bottom-margin">First name and last name</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text text-block__text--single-bottom-margin">Your messages to the Service (e.g. chat logs and support tickets)</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text text-block__text--single-bottom-margin">Other data you choose to give us (e.g. data to identify your issue with the Service or identify a lost account)</p>
                </li>
            </ul>
            <p class="text-block__text text-block__text--double-margin text-block__text--bold-small">
                Cookies and Usage Data
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Usage Data
            </p>
            <p class="text-block__text text-block__text--double-margin">
                We may also collect information that your browser sends whenever you visit our Service or when you access the Service by or through a mobile device ("Usage Data").
            </p>
            <p class="text-block__text text-block__text--double-margin">
                This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service
                that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                When you access the Service by or through a mobile device, this Usage Data may include information such as the type of mobile device you use, your mobile device unique ID,
                the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                We also create our own specific ID for you when you use the Service.
            </p>
            <p class="text-block__text text-block__text--double-margin text-block__text--bold-small">
                Tracking Cookies Data
            </p>
            <p class="text-block__text text-block__text--double-margin">
                We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device.
                Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Examples of Cookies we use:
            </p>
            <ul class="text-block__list">
                <li class="text-block__item">
                    <p class="text-block__text text-block__text--single-bottom-margin">Session Cookies. We use Session Cookies to operate our Service.</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text text-block__text--single-bottom-margin">Preference Cookies. We use Preference Cookies to remember your preferences and various settings.</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text text-block__text--single-bottom-margin">Security Cookies. We use Security Cookies for security purposes.</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text text-block__text--single-bottom-margin">Advertising Cookies. Advertising Cookies are used to serve you with advertisements that may be relevant to you and your interests.</p>
                </li>
            </ul>
            <p class="text-block__text text-block__text--double-margin">
                Third-Party Data
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Data we receive from our partners. This data may include information we receive if you link a third party service (e.g. Meta, Google) with the Service, information from platforms that the Service run on (e.g. to verify payments), information for advertising and analytics purposes.
            </p>
            <p class="text-block__text text-block__text--double-margin text-block__text--bold-small">
                Personal Data We Collect and Process
            </p>
            <p class="text-block__text text-block__text--double-margin">
                If you play TOO GSL Aiti’s games, your information will be processed in the ways described in this Privacy Policy:
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Device, game ID and information related to any purchases you make in our Services. To provide you with the product(s) that you have purchased in the app.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                We will process any personal information that you provide to us, for example your name and email address when you contact us or details about your device when you download our games.
            </p>
            <p class="text-block__text text-block__text--double-margin text-block__text--bold-small">
                Other Sources
            </p>
            <p class="text-block__text text-block__text--double-margin">
                We may collect information from other third-party sources.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                We may collect or receive information about you from other sources, including third-party information providers, and/or Apple App Store and Google Play App Store platforms when you purchase,
                download and/or play our games via these platforms. We use this information along with information you provide us directly, for example, to help you connect or to serve you advertising more tailored to your interests.
            </p>
            <p class="text-block__text text-block__text--double-margin text-block__text--bold-small">
                Use of Data
            </p>
            <p class="text-block__text text-block__text--double-margin">
                TOO GSL Aiti uses the collected data for various purposes:
            </p>
            <ul class="text-block__list">
                <li class="text-block__item">
                    <p class="text-block__text text-block__text--single-bottom-margin">To provide and maintain our Service</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text text-block__text--single-bottom-margin">To notify you about changes to our Service</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text text-block__text--single-bottom-margin">To allow you to participate in interactive features of our Service when you choose to do so</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text text-block__text--single-bottom-margin">To provide customer support</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text text-block__text--single-bottom-margin">To gather analysis or valuable information so that we can improve our Service</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text text-block__text--single-bottom-margin">To monitor the usage of our Service</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text text-block__text--single-bottom-margin">To detect, prevent and address technical issues</p>
                </li>
            </ul>
            <p class="text-block__text text-block__text--double-margin">
                Legal Basis for Processing Personal Data Under General Data Protection Regulation (GDPR)
            </p>
            <p class="text-block__text text-block__text--double-margin">
                If you are from the European Economic Area (EEA), TOO GSL Aiti's legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Data we collect and the specific context in which we collect it.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                TOO GSL Aiti may process your Personal Data because:
            </p>
            <ul class="text-block__list">
                <li class="text-block__item">
                    <p class="text-block__text text-block__text--single-bottom-margin">We need to perform a contract with you</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text text-block__text--single-bottom-margin">The processing is in our legitimate interests and it's not overridden by your rights</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text text-block__text--single-bottom-margin">For payment processing purposes</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text text-block__text--single-bottom-margin">We need to perform a contract with you</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text text-block__text--single-bottom-margin">To comply with the law</p>
                </li>
            </ul>
            <p class="text-block__text text-block__text--double-margin text-block__text--bold-small">
                Retention of Data
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Your information, including Personal Data, may be transferred to and maintained on computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                TOO GSL Aiti will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data
                will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information.
            </p>
            <p class="text-block__text text-block__text--double-margin text-block__text--bold-small">
                Disclosure Of Data
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Disclosure for Law Enforcement
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Under certain circumstances, TOO GSL Aiti may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Legal Requirements
            </p>
            <p class="text-block__text text-block__text--double-margin">
                TOO GSL Aiti may disclose your Personal Data in the good faith belief that such action is necessary:
            </p>
            <ul class="text-block__list">
                <li class="text-block__item">
                    <p class="text-block__text text-block__text--single-bottom-margin">To comply with a legal obligation</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text text-block__text--single-bottom-margin">To protect and defend the rights or property of TOO GSL Aiti</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text text-block__text--single-bottom-margin">To prevent or investigate possible wrongdoing in connection with the Service</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text text-block__text--single-bottom-margin">To protect the personal safety of users of the Service or the public</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text text-block__text--single-bottom-margin">To protect against legal liability</p>
                </li>
            </ul>
            <p class="text-block__text text-block__text--double-margin text-block__text--bold-small">
                Security Of Data
            </p>
            <p class="text-block__text text-block__text--double-margin">
                In order to help ensure the security of your data, we are developing and implementing administrative, technical and physical security measures to protect your data from
                unauthorised access or against loss, misuse or alteration.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure.
                While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                "Do Not Track" Signals
            </p>
            <p class="text-block__text text-block__text--double-margin">
                We do not support Do Not Track ("DNT"). Do Not Track is a preference you can set in your web browser to inform websites that you do not want to be tracked.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                You can opt-out of interest-based advertising on mobile applications by checking the privacy settings of your Android or iOS device and selecting
                "limit ad tracking" (Apple iOS) or "opt-out of interest based ads" (Android).
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Your Data Protection Rights Under General Data Protection Regulation (GDPR)
            </p>
            <p class="text-block__text text-block__text--double-margin">
                If you are a resident of the European Economic Area (EEA), you have certain data protection rights. TOO GSL Aiti aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please contact us.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                In certain circumstances, you have the following data protection rights:
            </p>
            <ul class="text-block__list">
                <li class="text-block__item">
                    <p class="text-block__text text-block__text--single-bottom-margin">The right to access, update or to delete the information we have on you.</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text text-block__text--single-bottom-margin">Whenever made possible, you can access, update or request deletion of your Personal Data. To perform these actions, please contact us to assist you.</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text text-block__text--single-bottom-margin">The right of rectification. You have the right to have your information rectified if that information is inaccurate or incomplete.</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text text-block__text--single-bottom-margin">The right to object. You have the right to object to our processing of your Personal Data.</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text text-block__text--single-bottom-margin">The right of restriction. You have the right to request that we restrict the processing of your personal information.</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text text-block__text--single-bottom-margin">
                        The right to data portability. You have the right to be provided with a copy of the information we have
                        on you in a structured, machine-readable and commonly used format.
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text text-block__text--single-bottom-margin">
                        The right to withdraw consent. You also have the right to withdraw your consent at any time where TOO GSL Aiti relied on your consent to process your personal information.
                    </p>
                </li>
            </ul>
            <p class="text-block__text text-block__text--double-margin">
                Please note that we may ask you to verify your identity before responding to such requests.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA).
            </p>
            <p class="text-block__text text-block__text--double-margin text-block__text--bold-small">
                Deletion or Modification of Personal Information
            </p>
            <p class="text-block__text text-block__text--double-margin">
                If for any reason you wish to modify or delete your Personal Information please send us an email to <a href="mailto:info@gslgames.com">info@gslgames.com</a>, and we will make reasonable efforts to modify or delete any such Personal Information pursuant to any applicable privacy laws.
            </p>
            <p class="text-block__text text-block__text--double-margin text-block__text--bold-small">
                Service Providers
            </p>
            <p class="text-block__text text-block__text--double-margin">
                We may employ third party companies and individuals to facilitate our Service ("Service Providers"), to provide the Service on our behalf, to perform Service-related services or to assist us in analysing how our Service is used.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
            </p>
            <p class="text-block__text text-block__text--double-margin text-block__text--bold-small">
                Analytics
            </p>
            <p class="text-block__text text-block__text--double-margin">
                We may use third-party Service Providers to monitor and analyse the use of our Service.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Google Analytics
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service.
                This data is shared with other Google services. Google may use the collected data to contextualise and personalise the ads of its own advertising network.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                For more information on the privacy practices of Google, please visit the <a href="https://policies.google.com/privacy?hl=en">Google Privacy Terms web page</a>.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Amplitude
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Amplitude is an analytics service.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                We also encourage you to review the <a href="https://amplitude.com/privacy">Amplitude policy</a> for safeguarding your data
            </p>
            <p class="text-block__text text-block__text--double-margin">
                AppMetrica
            </p>
            <p class="text-block__text text-block__text--double-margin">
                AppMetrica is an analytics service provided by YANDEX, Oy.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                We also encourage you to review the <a href="https://yandex.ru/legal/confidential/">AppMetrica policy</a> for safeguarding your data
            </p>
            <p class="text-block__text text-block__text--double-margin">
                AppsFlyer
            </p>
            <p class="text-block__text text-block__text--double-margin">
                AppsFlyer is an analytics service provided by AppsFlyer Ltd.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                We also encourage you to review the <a href="https://www.appsflyer.com/privacy-policy/">AppsFlyer policy</a> for safeguarding your data
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Adjust
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Adjust is an analytics service provided by Adjust GmbH.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                We also encourage you to review the <a href="https://www.adjust.com/terms/privacy-policy/">Adjust policy</a> for safeguarding your data
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Kochava
            </p>
            <p class="text-block__text text-block__text--double-margin">
                We collect the following categories of your personal information and may sell it to third parties: identifiers, commercial information,
                internet or other electronic network activity information, geolocation data, and inferences drawn from these categories.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                We also encourage you to review the <a href="https://www.kochava.com/support-privacy/">Kochava policy</a> for safeguarding your data
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Unity Analytics
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Unity Analytics is provided by Unity Technologies.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                We also encourage you to review the <a href="https://unity3d.com/legal/privacy-policy">Unity Technologies policy</a> for safeguarding your data
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Meta Products
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Meta Products is provided by Meta Platforms, Inc. and Meta Platforms Ireland Ltd.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                We also encourage you to review the <a href="https://www.facebook.com/privacy/explanation">Meta policy</a> for safeguarding your data
            </p>
            <p class="text-block__text text-block__text--double-margin text-block__text--bold-small">
                Advertising
            </p>
            <p class="text-block__text text-block__text--double-margin">
                We may use third-party Service Providers to show advertisements to you to help support and maintain our Service.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                AdMob by Google
            </p>
            <p class="text-block__text text-block__text">
                AdMob by Google is provided by Google Inc.
            </p>
            <p class="text-block__text text-block__text">
                You can opt-out from AdMob by Google service by following the <a href="https://www.applovin.com/optout">instructions</a> described by Google
                For more information on how Google uses the collected information, please visit the <a href="http://www.google.com/policies/privacy/partners/">How Google uses data when you use our partners' sites or app</a> page or visit the <a href="http://www.google.com/policies/privacy/">Privacy Policy of Google</a>
            </p>
            <p class="text-block__text text-block__text--double-margin">
                AppLovin
            </p>
            <p class="text-block__text text-block__text">
                AppLovin is provided by the AppLovin Corporation.
            </p>
            <p class="text-block__text text-block__text">
                If you'd like to opt-out from AppLovin service, please visit the <a href="https://www.applovin.com/optout">AppLovin Opt-out</a> page
            </p>
            <p class="text-block__text text-block__text--single-bottom-margin">
                If you'd like to learn more about AppLovin, please visit the <a href="https://www.applovin.com/privacy">Privacy Policy page</a> of AppLovin
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Unity Ads
            </p>
            <p class="text-block__text text-block__text">
                Unity Ads is provided by Unity Technologies.
            </p>
            <p class="text-block__text text-block__text">
                If you'd like to learn more about Unity Ads, please visit the <a href="https://unity3d.com/legal/privacy-policy">Privacy Policy</a> page of Unity Technologies
            </p>
            <p class="text-block__text text-block__text--double-margin text-block__text--bold-small">
                Behavioral Remarketing
            </p>
            <p class="text-block__text text-block__text--double-margin">
                TOO GSL Aiti uses remarketing services to advertise on third party websites to you after you visited our Service. We and our third-party vendors use cookies to inform, optimise and serve ads based on your past visits to our Service.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Google AdWords
            </p>
            <p class="text-block__text text-block__text">
                Google AdWords remarketing service is provided by Google Inc.
            </p>
            <p class="text-block__text text-block__text">
                You can opt-out of Google Analytics for Display Advertising and customise the Google Display Network ads by visiting the <a href="http://www.google.com/settings/ads">Google Ads Settings</a> page.
            </p>
            <p class="text-block__text text-block__text">
                Google also recommends installing the <a href="http://www.google.com/settings/ads">Google Analytics Opt-out Browser Add-on</a> - for your web browser. Google Analytics Opt-out Browser Add-on
                provides visitors with the ability to prevent their data from being collected and used by Google Analytics.
            </p>
            <p class="text-block__text text-block__text">
                For more information on the privacy practices of Google, please visit the <a href="http://www.google.com/settings/ads">Google Privacy Terms</a> web page.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Twitter
            </p>
            <p class="text-block__text text-block__text">
                Twitter remarketing service is provided by Twitter Inc.
            </p>
            <p class="text-block__text text-block__text">
                You can opt-out from Twitter's interest-based ads by following their <a href="https://support.twitter.com/articles/20170405">instructions</a>.
            </p>
            <p class="text-block__text text-block__text">
                You can learn more about the privacy practices and policies of Twitter by visiting their <a href="https://twitter.com/privacy">Privacy Policy</a> page
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Meta
            </p>
            <p class="text-block__text text-block__text">
                Meta remarketing service is provided by Meta Platforms, Inc. and Meta Platforms Ireland Ltd.
            </p>
            <p class="text-block__text text-block__text">
                You can learn more about interest-based advertising from Meta by visiting <a href="https://www.facebook.com/help/164968693837950">this</a> page.
            </p>
            <p class="text-block__text text-block__text">
                To opt-out from Meta's interest-based ads follow these <a href="https://www.facebook.com/help/568137493302217">instructions</a> from Meta.
            </p>
            <p class="text-block__text text-block__text">
                Meta adheres to the Self-Regulatory Principles for Online Behavioral Advertising established by the Digital Advertising Alliance.
                You can also opt-out from Meta and other participating companies through the <a href="http://www.aboutads.info/choices/">Digital Advertising Alliance in the USA</a>,
                the <a href="http://youradchoices.ca/">Digital Advertising Alliance of Canada</a> in Canada or <a href="http://www.youronlinechoices.eu/">the European Interactive Digital Advertising Alliance</a> in
                Europe, or opt- out using your mobile device settings. For more information on the privacy practices of Meta, please visit <a href="https://www.facebook.com/privacy/explanation">Meta's Data Policy</a>
            </p>
            <p class="text-block__text text-block__text--double-margin text-block__text--bold-small">
                Customer support
            </p>
            <p class="text-block__text text-block__text--double-margin">
                TOO GSL Aiti uses third party customer support services to provide customer support within the Service.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Helpshift
            </p>
            <p class="text-block__text text-block__text">
                Helpshift customer support service provided by Helpshift, Inc.
            </p>
            <p class="text-block__text text-block__text">
                You can learn more about the privacy practices and policies of Helpshift by visiting their <a href="https://www.helpshift.com/legal/privacy/">Privacy Policy</a> page
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Zendesk
            </p>
            <p class="text-block__text text-block__text">
                Zendesk customer support service provided by Zendesk, Inc.
            </p>
            <p class="text-block__text text-block__text">
                You can learn more about the privacy practices and policies of Zendesk by visiting their <a href="https://www.zendesk.com/company/customers-partners/privacy-policy/">Privacy Policy</a> page
            </p>
            <p class="text-block__text text-block__text--double-margin text-block__text--bold-small">
                Payments
            </p>
            <p class="text-block__text text-block__text--double-margin">
                We may provide paid products and/or services within the Service. In that case, we use third-party services for payment processing (e.g. payment processors).
            </p>
            <p class="text-block__text text-block__text">
                We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy.
                These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard,
                American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                The payment processors we work with are:
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Apple Store In-App Payments
            </p>
            <p class="text-block__text text-block__text">
                Their Privacy Policy can be viewed <a href="https://www.apple.com/legal/privacy/en-ww/">here</a>
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Google Play In-App Payments
            </p>
            <p class="text-block__text text-block__text">
                Their Privacy Policy can be viewed <a href="https://www.google.com/policies/privacy/">here</a>
            </p>
            <p class="text-block__text text-block__text--double-margin text-block__text--bold-small">
                Links To Other Sites
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site.
                We strongly advise you to review the Privacy Policy of every site you visit.
            </p>
            <p class="text-block__text text-block__text">
                We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
            </p>
            <p class="text-block__text text-block__text--double-margin text-block__text--bold-small">
                Children's Privacy
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Our Service does not address anyone under the age of 18 ("Children").
            </p>
            <p class="text-block__text text-block__text">
                We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Child has
                provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent,
                we take steps to remove that information from our servers.
            </p>
            <p class="text-block__text text-block__text--double-margin text-block__text--bold-small">
                Changes To This Privacy Policy
            </p>
            <p class="text-block__text text-block__text--double-margin">
                We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
            </p>
            <p class="text-block__text text-block__text">
                We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.
            </p>
            <p class="text-block__text text-block__text">
                You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
            </p>
            <p class="text-block__text text-block__text--double-margin text-block__text--bold-small">
                Contact Us
            </p>
            <p class="text-block__text text-block__text--double-margin">
                If you have any questions about this Privacy Policy, please contact us by email:  <a href="mailto:info@gslgames.com">info@gslgames.com</a>
            </p>
            <!-- <p class="text-block__text text-block__text--quarter-margin text-block__text--bold grad-text">Tracking Cookies Data</p>
            <p class="text-block__text text-block__text--double-margin">You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
            <p class="text-block__text text-block__text--double-margin">Examples of Cookies we use:</p>
            <ul class="text-block__list">
                <li class="text-block__item">
                    <p class="text-block__text text-block__text--single-bottom-margin">Session Cookies. We use Session Cookies to operate our Service.</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text text-block__text--single-bottom-margin">Preference Cookies. We use Preference Cookies to remember your preferences and various settings.</p>
                </li>
            </ul>
            <p class="text-block__text text-block__text--single-bottom-margin">For more information on the privacy practices of Google, please visit the <a class="text-block__inline-link" href="https://policies.google.com/privacy?hl=en">Google Privacy Terms web page</a></p> -->
        </section>
    </main>
</template>

<script>
import LayoutHeader from '@/app/common/pkg/LayoutHeader.vue'

export default {
    components: {LayoutHeader}
}
</script>

<style lang="scss">
@import "~@/app/styles/text-block.scss";
@import "~@/app/styles/main-content.scss";
</style>
