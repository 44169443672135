<template>
    <LayoutHeader />
    <main class="main-content">
        <h1 class="main-content__heading grad-text">Terms of Use</h1>
        <section class="main-content__text-block text-block">
            <p class="text-block__text">
                TOO "GSL Aiti"® Terms of Use
            </p>
            <p class="text-block__text text-block__text--double-margin">
                ATTENTION, NOTICE TO USER: THIS IS AN AGREEMENT GOVERNING YOUR RELATIONSHIP WITH TOO "GSL AITI" (“TOO "GSL AITI"”)
                AND USE OF TOO "GSL AITI"’S SERVICES (AS SUCH TERM DEFINED BELOW) AVAILABLE ON TOO "GSL AITI".COM AND OTHER VARIOUS
                WEBSITES UNDER TOO "GSL AITI"’S CONTROL (COLLECTIVELY (“SITE”) AND TOO "GSL AITI"'S DOMAINS INCLUDED WITHIN
                THIS SITE PROVIDED TO YOU BY TOO "GSL AITI". YOU MAY USE THIS SITE AND/OR THE SERVICES ONLY ON THE CONDITION
                THAT YOU ACCEPT ALL OF THE TERMS AND CONDITIONS CONTAINED HEREIN. PLEASE READ THESE TERMS CAREFULLY BEFORE USING
                THIS SITE AND SERVICES. USING THIS SITE AND/OR SERVICES INDICATE THAT YOU ACCEPT THESE TERMS.
                IF YOU DO NOT ACCEPT THESE TERMS (“TERMS OF USE”), DO NOT USE THIS SITE AND/OR SERVICE.
            </p>
            <p class="text-block__text text-block__text--quarter-margin text-block__text--bold  grad-text">
                Acceptance of Terms of Use
            </p>
            <p class="text-block__text text-block__text--double-margin">
                TOO "GSL Aiti" makes this Site including all information, graphics, documents, text, games and
                all other elements of the Site and all products offered on this Site and service operated through
                the Site (“Service”), available for your use subject to the terms and conditions set forth
                in this document. By accessing and using this Site, use of any TOO "GSL Aiti"’s Service available
                through this Site you agree to be bound by the following Terms of Use and all terms and conditions
                contained and/or referenced herein or any additional terms and conditions set forth on this Site
                and all such terms shall be deemed accepted by you. If you do NOT agree to all these Terms of Use,
                you should NOT use this Site and/or Service. If you do not agree to any additional specific terms
                which apply to particular Content (as defined below) or to particular transactions concluded through
                this Site, then you should NOT use the part of the Site, which contains such Content or through
                which such transactions may be concluded and you should not use such Content or conclude
                such transactions.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                The following restrictions and conditions apply to the use of Service and creating and maintaining
                the Account (as such term defined below):
            </p>
            <ul class="text-block__list">
                <li class="text-block__item">
                    <p class="text-block__text">
                        You shall not create an account in connection with the Site and/or Service (an “Account”),
                        or access Service if you are under the age of 13;
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        You shall monitor your Account to restrict use by minors, and you shall deny access to children under the age of 13.
                        You accept full responsibility for any unauthorized use of Service by minors in connection with your Account.
                        You are solely responsible for any use of your credit card or other payment instrument (e.g. PayPal) by minors;
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        You shall not create an Account or use Service if you are a convicted sex offender;
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        You shall not have an Account or use Service if you have previously been removed by TOO "GSL Aiti" or previously have been banned from playing any TOO "GSL Aiti"’s games;
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        You shall not use Service if you are located in a country embargoed by the United States or if you are a resident of any nation that is on the U.S.
                        Treasury Department’s list of Specially Designated Nationals.
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        You shall use your Account only for non-commercial purposes;
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        You shall not use your Account to advertise, solicit, or transmit any commercial advertisements, including chain letters, junk e-mail or repetitive messages (spim and spam) to any other user or third party;
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        You shall not use your Account to engage in any illegal conduct;
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        You shall not sublicense, rent, lease, sell, trade, gift, bequeath or otherwise transfer your Account to anyone without TOO "GSL Aiti"’s written permission;
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        You shall not use your Account to engage in any illegal conduct;
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        You shall not access or use an Account that has been sublicensed, rented, leased, sold, traded, gifted, bequeathed, or otherwise transferred from the original Account creator without TOO "GSL Aiti"’s consent.
                    </p>
                </li>
            </ul>
            <p class="text-block__text text-block__text--double-margin">
                Notwithstanding the foregoing, TOO "GSL Aiti" may refuse to provide Services to any person for any reason or no reason whatsoever.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                These Terms of Use may be amended by TOO "GSL Aiti" upon notice given by one or more of the following means: through the Site at or after you login to your Account,
                by the email communication to the address provided by you when you setup your Account, or by written mail communication to the address on record for your Account.
                Failure to provide or maintain accurate or current contact information by you will not avail you from responsibility to comply with these Terms of Use as amended from time to time.
                Please check the Terms of Use published on this Site regularly to ensure that you are aware of all terms governing your use of this Site. In addition, specific terms and conditions may apply to specific content, games,
                products, materials, Service or information contained on or available through this Site (the “Content”) or transactions concluded through this Site.
                Such specific terms may be in addition to these Terms of Use or, where inconsistent with these Terms of Use, only to the extent the content or intent of such specific terms is inconsistent with these Terms of Use,
                such specific terms will supersede these Terms of Use.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                TOO "GSL Aiti" reserves the right to make changes or updates with respect to or in the Content of the Site or the format thereof at any time without notice.
                TOO "GSL Aiti" reserves the right to terminate or restrict your access to the Site for any reason whatsoever at its sole discretion.
            </p>
            <p class="text-block__text text-block__text--quarter-margin text-block__text--bold  grad-text">
                Description of Service
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Through use of your personal computer or other electronic devices (“Device”) and this Site you may play TOO "GSL Aiti"’s games and use related Services available on the Site.
            </p>
            <p class="text-block__text text-block__text--quarter-margin text-block__text--bold  grad-text">
                Account Information
            </p>
            <p class="text-block__text text-block__text--double-margin">
                When creating or updating your Account, you may be required to provide TOO "GSL Aiti" with certain personal information, including but not limited to your name, birth date, gender, e-mail address, mailing addresses,
                location, login and password. This information will be held and used in accordance with TOO "GSL Aiti"’s <a href="gslgames.com/privacy-policy/">Privacy Policy</a>.
                You agree that you will supply accurate and complete information to TOO "GSL Aiti", and that you will update such information promptly.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                You hereby acknowledge and agree that your login, number, name and profile picture may be publicly available to other users and some search engines may index your name and profile photo, if any.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                During the Account setup process, you may be required to select a specific username and password (“Login Information”). The following rules govern the security of your Login Information:
            </p>
            <ul class="text-block__list">
                <li class="text-block__item">
                    <p class="text-block__text">
                        You shall not share the Account or the Login Information with any third party, let anyone else access or use your Account, or do anything else that might jeopardize the security of your Account;
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        In the event you become aware of or reasonably suspect any breach of security, including without limitation any loss, theft, or
                        unauthorized disclosure of the Login Information, you must immediately notify TOO "GSL Aiti" and modify your Login Information;
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        You are solely responsible for maintaining the confidentiality of the Login Information, and you will be responsible for all uses of the Login Information,
                        including purchases, whether or not authorized by you; and
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        You are solely responsible for any unauthorized use of your Account.
                    </p>
                </li>
            </ul>
            <p class="text-block__text text-block__text--double-margin">
                TOO "GSL Aiti" reserves the right to remove or reclaim any usernames at any time and for any reason, including but not limited to claims by a third party that your username violates such third party’s rights.
            </p>
            <p class="text-block__text text-block__text--quarter-margin text-block__text--bold  grad-text">
                Warranties and Disclaimers
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Although care has been taken to ensure the accuracy of the information on this Site, TOO "GSL Aiti" assumes no responsibility therefor.
                ALL CONTENT IS PROVIDED "AS IS" AND "AS AVAILABLE." TOO "GSL AITI" HEREBY EXPRESSLY DISCLAIMS ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED,
                INCLUDING WITHOUT LIMITATION WARRANTIES OF MERCHANTABILITY, FITNESS FOR ANY PARTICULAR PURPOSE, NON-INFRINGEMENT, OR AS TO THE OPERATION OF THIS SITE OR THE CONTENT.
                TOO "GSL AITI" DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS AS TO THE SECURITY OF THIS SITE. YOU ACKNOWLEDGE ANY INFORMATION SENT MAY BE INTERCEPTED. TOO "GSL AITI" DOES NOT WARRANT
                THAT THE SITE OR THE SERVERS WHICH MAKE THIS SITE AVAILABLE OR ELECTRONIC COMMUNICATIONS SENT BY TOO "GSL AITI" ARE FREE FROM VIRUSES OR ANY OTHER HARMFUL ELEMENTS.
                Except as agreed by TOO "GSL Aiti" in writing, contractors, agents, dealers or distributors of TOO "GSL Aiti" or any other third party shall not have a right to modify this limited warranty,
                nor to make any additional warranties.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                The use of the Service through the Site is done at your own discretion and risk and with your agreement that you will be solely responsible for any damage to your Device,
                loss of data, or other harm that results from such activities. TOO "GSL Aiti" assumes no liability for any computer virus or other similar software code that is downloaded to
                your Device from the Site or in connection with any Service or products offered through the Site. No advice or information whether oral or written, obtained by you from TOO
                "GSL Aiti" or from the Site shall create any warranty not expressly stated in the terms of use.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                The Site may contain references to specific TOO "GSL Aiti" games and Service that may not be (readily) available in a particular country or region.
                Any such reference does not imply or warrant that any such products or Service shall be available at any time in any particular country or Device.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                IN NO EVENT SHALL TOO "GSL AITI" OR ANY OF ITS AFFILIATES BE LIABLE FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, PUNITIVE, SPECIAL OR INCIDENTAL DAMAGES
                (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF BUSINESS, CONTRACT, REVENUE, DATA, INFORMATION OR BUSINESS INTERRUPTION), UNDER ANY THEORY OF LIABILITY,
                RESULTING FROM, ARISING OUT OF OR IN CONNECTION WITH THE USE OF, OR INABILITY TO USE THIS SITE OR THE CONTENT, PRODUCTS, SERVICE, THE STATEMENTS OR ACTIONS OF ANY THIRD PARTY ON OR
                THROUGH THIS SITE, ANY UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSION OR DATA, ANY INFORMATION THAT IS SENT OR RECEIVED, OR NOT SENT OR RECEIVED, ANY FAILURE TO STORE DATA,
                ANY LOSS OF DATA, LOSS OR DAMAGE TO FILES, LOSS OR DAMAGE TO THE CONTENT, ANY SERVICES AVAILABLE THROUGH THE SITE THAT ARE DELAYED OR INTERRUPTED, EVEN IF TOO "GSL AITI" HAS BEEN ADVISED OF
                THE POSSIBILITY OF SUCH DAMAGES. ANY ACTION BROUGHT AGAINST TOO "GSL AITI" PERTAINING TO OR IN CONNECTION WITH THIS SITE MUST BE COMMENCED AND NOTIFIED TO TOO "GSL AITI" IN WRITING WITHIN ONE (1) YEAR AFTER
                THE DATE THE CAUSE FOR ACTION AROSE. THIS LIMITATION OF LIABILITY SHALL NOT APPLY TO LIABILITY FOR DEATH OR PERSONAL INJURY TO THE EXTENT THAT APPLICABLE LAW PROHIBITS SUCH LIMITATION. FURTHERMORE, BECAUSE SOME
                JURISDICTIONS DO NOT ALLOW OR LIMIT THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES OR DO NOT ALLOW THE EXCLUSION OR LIMITATION OF IMPLIED WARRANTIES, THE ABOVE LIMITATION MAY NOT APPLY TO ALL CIRCUMSTANCES.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Some jurisdictions do not allow the exclusion of implied warranties or limitations, so the above limitations may not apply to you.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Any use of the Service in violation of these Terms of Use is strictly prohibited and can result in the immediate termination of the Service and may subject you to liability for violations of law.
                ANY ATTEMPT BY YOU TO DISRUPT OR INTERFERE WITH THE SERVICE INCLUDING UNDERMINING OR MANIPULATING THE LEGITIMATE OPERATION OF ANY TOO "GSL AITI" GAME OR THE SITE IS A VIOLATION OF TOO "GSL AITI" POLICY AND MAY BE A VIOLATION OF APPLICABLE LAWS.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                You agree that you will not, under any circumstances:
            </p>
            <ul class="text-block__list">
                <li class="text-block__item">
                    <p class="text-block__text">
                        Engage in any act that TOO "GSL Aiti" deems in its reasonable discretion to be in conflict with the spirit or intent of the Service, including but not limited to circumventing or manipulating these Terms of Use, our game rules, game mechanics or policies;
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        Make improper use of TOO "GSL Aiti"’s Services, including, without limitation, by submitting false abuse reports or using profane and abusive language in your communications with our personnel; or
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        Use the Service, intentionally or unintentionally, in connection with any violation of any applicable law or regulation, or do anything that promotes the violation of any applicable law or regulation;
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        Use cheats, exploits, automation software, bots, hacks, mods or any unauthorized third party software designed to modify or interfere with the Service or any TOO "GSL Aiti" game experience;
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        Use the Service in order to design or assist in the design of cheats, automation software, bots, hacks, mods or any other unauthorized third party software designed to modify or interfere with the Service or any TOO "GSL Aiti" game experience;
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        Without TOO "GSL Aiti"’s express written consent, modify or cause to be modified any files that are a part of the Service;
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        Disrupt, overburden, or aid or assist in the disruption or overburdening of (1) any computer or server used to offer or support the Service, Site or any TOO "GSL Aiti" game environment (each a “Server”); or
                        (2) the enjoyment of the Service or any TOO "GSL Aiti" game by any other person;
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        Institute, assist or become involved in any type of attack, including without limitation distribution of a virus, denial of service attacks upon the Service, or other attempts to disrupt the
                        Service or any other person’s use or enjoyment of the Service; or
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        Attempt to gain unauthorized access to the Service, Accounts registered to others or to the computers, Servers, or networks connected to the Service by any means other than the user interface provided by
                        TOO "GSL Aiti", including, but not limited to, by circumventing or modifying, attempting to circumvent or modify, or encouraging or assisting any other person to circumvent or modify, any
                        security, technology, device, or software that is part of the Service;
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        Post any information that is abusive, threatening, obscene, defamatory, libelous, or racially, sexually, religiously, or otherwise objectionable or offensive;
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        Post any information that contains nudity, excessive violence, or offensive subject matter or that contains a link to such content;
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        Transmit unauthorized communications through the Service, including junk mail, chain letters, spam and any materials that promote malware, spyware and downloadable items;
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        Interfere or attempt to interfere with the proper functioning of the Service or connect to or use the Service in any way not expressly permitted by these Terms of Use;
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        Use any unauthorized third party software that accesses, intercepts, “mines”, or otherwise collects information from or through the Service or that is in transit from or to the Service,
                        including, without limitation, any software that reads areas of RAM or streams of network traffic used by the Service to store information about TOO "GSL Aiti" game characters, elements,
                        or environment. TOO "GSL Aiti" may, at its sole and absolute discretion, allow the use of certain third party user interfaces;
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        Intercept, examine or otherwise observe any proprietary communications protocol used by a client, a Server, or the Service, whether through the use of a network analyzer, packet sniffer or other device;
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        Make any automated use of the system, or take any action that imposes or may impose (in our sole discretion) an unreasonable or disproportionately large load on our infrastructure;
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        Bypass any robot exclusion headers or other measures TOO "GSL Aiti" employs to restrict access to the service or use any software, technology, or device to send content or messages,
                        scrape, spider, or crawl the Service, or harvest or manipulate data;
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        Use, facilitate, create, or maintain any unauthorized connection to the Service, including without limitation (1) any connection to any unauthorized server that emulates, or attempts to
                        emulate any part of the Service; or (2) any connection using programs, tools, or software not expressly approved by TOO "GSL Aiti";
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        Except where permitted by law or relevant open source licenses, reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for any underlying software or
                        other intellectual property used to provide the Service, or to obtain any information from the Service using any method not expressly permitted by TOO "GSL Aiti"; or
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        Copy, modify or distribute rights or content from any Site or game, or TOO "GSL Aiti"’s copyrights or trademarks or use any method to copy or distribute the content of the
                        Service except as specifically allowed in these Terms of Use;
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        Solicit or attempt to solicit personal information from other users of the Service;
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        Collect, harvest or post anyone’s private information, including personally identifiable information (whether in text, image or video form), identification documents, or
                        financial information through the Service; or
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        Upload or transmit or attempt to upload or transmit, without TOO "GSL Aiti"’s express permission, any material that acts as a passive or active information collection or
                        transmission mechanism, including, without limitation, clear graphics interchange formats ("gifs"), 1x1 pixels, web bugs, cookies or other similar devices
                        (sometimes referred to as "spyware," "passive collection mechanisms" or "pcms").
                    </p>
                </li>
            </ul>
            <p class="text-block__text text-block__text--quarter-margin text-block__text--bold  grad-text">
                Term and Termination
            </p>
            <p class="text-block__text text-block__text--double-margin">
                The term of this Terms of Use (“Term”) shall begin when you start using this Site and/or Service and shall continue in perpetuity unless otherwise terminated by TOO "GSL Aiti" by written notice.
                TOO "GSL Aiti" expressly reserves the right to change, suspend or discontinue all the Service or portion thereof, at any time, and may terminate your use of the Service at any time.
                Without prejudice to any other rights, these Terms of Use will terminate automatically if you fail to comply with any of the limitations or other requirements described herein.
                Upon any termination or expiration of these Terms of Use, you must immediately cease using the Service including without limitation any use of TOO "GSL Aiti"’s trademarks, trade names,
                copyrights and other intellectual property.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                WITHOUT LIMITING ANY OTHER REMEDIES, TOO "GSL AITI" MAY LIMIT, SUSPEND, TERMINATE, MODIFY, OR DELETE ACCOUNTS OR ACCESS TO THE SERVICE OR PORTIONS THEREOF IF YOU ARE, OR TOO "GSL AITI"
                REASONABLY SUSPECTS THAT YOU ARE, FAILING TO COMPLY WITH ANY OF THIS TERMS OF USE OR FOR ANY ACTUAL OR SUSPECTED ILLEGAL OR IMPROPER USE OF THE SERVICE, WITH OR WITHOUT NOTICE TO YOU.
                YOU CAN LOSE YOUR USER NAME AND PERSONA/AVATAR AS A RESULT OF ACCOUNT TERMINATION OR LIMITATION, AS WELL AS ANY BENEFITS, PRIVILEGES, EARNED ITEMS AND PURCHASED ITEMS ASSOCIATED WITH YOUR
                USE OF THE SERVICE, AND TOO "GSL AITI" IS UNDER NO OBLIGATION TO COMPENSATE YOU FOR ANY SUCH LOSSES OR RESULTS.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                WITHOUT LIMITING OUR OTHER REMEDIES, WE MAY LIMIT, SUSPEND OR TERMINATE THE SERVICE AND USER ACCOUNTS OR PORTIONS THEREOF, PROHIBIT ACCESS TO OUR GAMES AND SITES, AND THEIR CONTENT,
                SERVICES AND TOOLS, DELAY OR REMOVE HOSTED CONTENT, AND TAKE TECHNICAL AND LEGAL STEPS TO PREVENT USERS FROM ACCESSING THE SERVICE IF WE BELIEVE THAT THEY ARE CREATING RISK OR POSSIBLE
                LEGAL LIABILITIES, INFRINGING THE INTELLECTUAL PROPERTY RIGHTS OF THIRD PARTIES, OR ACTING INCONSISTENTLY WITH THE LETTER OR SPIRIT OF OUR TERMS OR POLICIES. ADDITIONALLY, WE MAY,
                IN APPROPRIATE CIRCUMSTANCES AND AT OUR SOLE DISCRETION, SUSPEND OR TERMINATE ACCOUNTS OF USERS WHO MAY BE REPEAT INFRINGERS OF THIRD PARTY INTELLECTUAL PROPERTY RIGHTS.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                TOO "GSL Aiti" reserves the right to stop offering and/or supporting the Service or a particular game or part of the Service at any time either permanently or temporarily,
                at which point your license to use the Service or a part thereof will be automatically terminated or suspended. In such event, unless such suspension or termination is caused by TOO
                "GSL Aiti"’s grossly negligent or willful acts, TOO "GSL Aiti" shall not be required to provide refunds, benefits or other compensation to users in connection with such discontinued elements of the Service.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Termination of your Account can include disabling your access to the Service or any part thereof including any content you submitted or others submitted.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                UPON TERMINATION OF THIS AGREEMENT YOU WILL NO LONGER BE AUTHORIZED TO USE THE SERVICE IN ANY WAY.
            </p>
            <p class="text-block__text text-block__text--quarter-margin text-block__text--bold  grad-text">
                Your Warranties
            </p>
            <p class="text-block__text text-block__text--double-margin">
                YOU HEREBY WARRANT THAT: (i) ALL INFORMATION PROVIDED BY YOU TO TOO "GSL AITI" IN CONNECTION WITH THIS SITE AND/OR SERVICE IS TRUE AND ACCURATE; (ii) YOU HAVE FULL POWER AND AUTHORITY TO ENTER INTO THE TERMS OF USE;
                (iii) YOU SHALL PERFORM ALL OF YOUR OBLIGATIONS UNDER THE TERMS OF USE IN ACCORDANCE WITH APPLICABLE LAWS; AND (v) YOUR MATERIALS, TEXT, GRAPHIC, AUDIOVISUAL, AND OTHER CONTENT (“USER MATERIALS”) THAT YOU MAKE
                AVAILABLE TO TOO "GSL AITI" OR OTHER END USERS OF THE SITE EITHER VIA UPLOAD OR OTHER MEANS AND THAT ARE NOT PROVIDED BY TOO "GSL AITI", OR SITE DO NOT (1) INFRINGE ANY INTELLECTUAL PROPERTY RIGHTS OF ANY THIRD PARTY,
                (2) CONSTITUTE DEFAMATION, LIBEL OR OBSCENITY, (3) RESULT IN ANY CONSUMER FRAUD, PRODUCT LIABILITY, BREACH OF CONTRACT TO WHICH YOU ARE A PARTY OR CAUSE INJURY TO ANY THIRD PARTY, (4) PROMOTE VIOLENCE OR CONTAIN HATE SPEECH,
                (5) VIOLATE ANY APPLICABLE LAW, STATUTE, ORDINANCE, OR REGULATIONS, OR (6) CONTAIN ADULT CONTENT OR PROMOTE ILLEGAL ACTIVITIES
            </p>
            <p class="text-block__text text-block__text--double-margin">
                This Site may provide links to other Sites that are not under the control of TOO "GSL Aiti". TOO "GSL Aiti" shall not be responsible in any way for the content of such other Sites. TOO "GSL Aiti" provides such links
                only as a convenience to the user of this Site, and the inclusion of any link to any such websites does not imply endorsement by TOO "GSL Aiti" of the content of such websites.
            </p>
            <p class="text-block__text text-block__text--quarter-margin text-block__text--bold  grad-text">
                Third Party Materials
            </p>
            <p class="text-block__text text-block__text--double-margin">
                In order to use this Site and/or Service, you may need to obtain and/or use certain third-party products (i.e. Device), services and/or materials (“Third Party Materials”). Third Party Materials are (i) not licensed hereunder;
                (ii) not under TOO "GSL Aiti"’s control or license; and (iii) subject to the applicable licenses and respective terms and conditions of such third parties which you need to enter into prior or subsequent to
                the installation and/or use of the Third Party Materials and prior to the effective use of this Site and/or Service. The list of such Third Party Materials may be provided by TOO "GSL Aiti" upon written request.
                Notwithstanding any provision to the contrary herein, nothing in these Terms of Use shall be construed as to grant You any rights or licenses with regard to such Third Party Materials or to entitle You to use such Third Party Materials.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                TOO "GSL Aiti" may also participate in a third party affiliate programs utilizing its Products, services and Site (“Affiliate Programs”). Most of these Affiliate Programs work seamlessly with the TOO "GSL Aiti"’s games and/or
                Services and do not effect or interfere with your operation of Site, games and/or Services or your user-experience, at the same time, essentially enabling TOO "GSL Aiti" to be credited for the results of your searches and
                user-activity on the Site. TOO "GSL Aiti"’s participation in the Affiliate Programs enables TOO "GSL Aiti" to enhance your enjoyment of Site, games and/or Services, while allowing TOO "GSL Aiti" to provide you with high quality
                free content that you can enjoy while visiting our Site and playing our games and using our Services. Please note however that such Affiliate Programs are not under TOO "GSL Aiti"’s control and subject to the applicable licenses,
                privacy policies and terms and conditions.
            </p>
            <p class="text-block__text text-block__text--quarter-margin text-block__text--bold  grad-text">
                Third Party Materials
            </p>
            <p class="text-block__text text-block__text--double-margin">
                YOU HEREBY WARRANT THAT: (i) ALL INFORMATION PROVIDED BY YOU TO TOO "GSL AITI" IN CONNECTION WITH THIS SITE AND/OR SERVICE IS TRUE AND ACCURATE; (ii) YOU HAVE FULL POWER AND AUTHORITY TO ENTER INTO THE TERMS OF USE;
                (iii) YOU SHALL PERFORM ALL OF YOUR OBLIGATIONS UNDER THE TERMS OF USE IN ACCORDANCE WITH APPLICABLE LAWS; AND (v) YOUR MATERIALS, TEXT, GRAPHIC, AUDIOVISUAL, AND OTHER CONTENT (“USER MATERIALS”) THAT YOU MAKE
                AVAILABLE TO TOO "GSL AITI" OR OTHER END USERS OF THE SITE EITHER VIA UPLOAD OR OTHER MEANS AND THAT ARE NOT PROVIDED BY TOO "GSL AITI", OR SITE DO NOT (1) INFRINGE ANY INTELLECTUAL PROPERTY RIGHTS OF ANY THIRD PARTY,
                (2) CONSTITUTE DEFAMATION, LIBEL OR OBSCENITY, (3) RESULT IN ANY CONSUMER FRAUD, PRODUCT LIABILITY, BREACH OF CONTRACT TO WHICH YOU ARE A PARTY OR CAUSE INJURY TO ANY THIRD PARTY, (4) PROMOTE VIOLENCE OR CONTAIN HATE SPEECH,
                (5) VIOLATE ANY APPLICABLE LAW, STATUTE, ORDINANCE, OR REGULATIONS, OR (6) CONTAIN ADULT CONTENT OR PROMOTE ILLEGAL ACTIVITIES
            </p>
            <p class="text-block__text text-block__text--double-margin">
                This Site may provide links to other Sites that are not under the control of TOO "GSL Aiti". TOO "GSL Aiti" shall not be responsible in any way for the content of such other Sites. TOO "GSL Aiti" provides such links
                only as a convenience to the user of this Site, and the inclusion of any link to any such websites does not imply endorsement by TOO "GSL Aiti" of the content of such websites.
            </p>
            <p class="text-block__text text-block__text--quarter-margin text-block__text--bold  grad-text">
                Intellectual Property
            </p>
            <p class="text-block__text text-block__text--double-margin">
                You hereby expressly agree that all rights, title, and interest in and to all intellectual property rights, including, without limitation, patents, copyright, trademark, trade secrets and all other related proprietary
                rights in this Site and/or Content (including but not limited to Software, Service, audio, video, text and photographs) rests with TOO "GSL Aiti" and/or its licensors TOO "GSL Aiti" and/or its licensors are the sole and
                exclusive owners thereof. All rights in the Site and/or Content not expressly granted herein are reserved. You agree not to copy, republish, frame, download, transmit, modify, rent, lease, loan, sell, assign, distribute,
                license, sublicense, reverse engineer, or create derivative works based on the Site and/or Content, its products or Service except as expressly authorized herein. Except as otherwise provided, the Content published on this Site
                may be reproduced or distributed in unmodified form for personal non-commercial use only. Any other use of the Content, including without limitation distribution, reproduction, modification, display or transmission without
                the prior written consent of TOO "GSL Aiti" is strictly prohibited. All copyright and other proprietary notices shall be retained on all reproductions.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                TOO "GSL Aiti" hereby disclaims any rights to trademarks, Service marks, trade names, logos, copyright, patents, domain names or other intellectual property interests of third parties.
                All intellectual property interests of third parties listed above are the properties of their respective owners. Third Party Material are the properties of their respective owners.
                TOO "GSL Aiti" disclaims any proprietary interests in the intellectual property rights other than its own.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                You hereby grant TOO "GSL Aiti" and TOO "GSL Aiti" accepts a non-exclusive and fully transferable, sublicensable and assignable royalty free right to store, load, adapt, modify, revise, transform, alter, create derivative works,
                install, execute, display and share with any third party or other users of TOO "GSL Aiti"’s Service (to “Use”) User Materials in connection with the TOO "GSL Aiti"’s Service, on the Site in connection with TOO "GSL Aiti"’s games
                and Service pursuant to the terms and conditions of these Terms of Use with the right to sublicense to sublicensee(s) and end users any rights granted hereunder substantially on the terms and conditions set forth herein (the “License”).
            </p>
            <p class="text-block__text text-block__text--quarter-margin text-block__text--bold  grad-text">
                Information Provided by YOU/Feedback
            </p>
            <p class="text-block__text text-block__text--double-margin">
                You may from time to time provide suggestions, specifications, comments or other feedback to TOO "GSL Aiti" via TOO "GSL Aiti"’s forums or otherwise with respect to TOO "GSL Aiti"’s Site,
                games or Services (hereinafter “Feedback”). You hereby agree that all Feedback is and shall be entirely voluntary and (i) shall be deemed a derivative work based on TOO "GSL Aiti"’s proprietary materials,
                (ii) shall be owned by TOO "GSL Aiti", and (iii) absent separate agreement, shall not create any confidentiality obligation for TOO "GSL Aiti". However, TOO "GSL Aiti" shall not disclose the source of any Feedback without your consent.
                Except as otherwise provided herein, TOO "GSL Aiti" shall be free to use such Feedback as it sees fit, entirely without obligation of any kind.
            </p>
            <p class="text-block__text text-block__text--quarter-margin text-block__text--bold  grad-text">
                Notice and Procedure for Making Claims of Copyright Infringement
            </p>
            <p class="text-block__text text-block__text--double-margin">
                If you believe that your work has been copied in a way that constitutes copyright infringement, please provide TOO "GSL Aiti"’s Copyright Agent the written information specified below.
                Please note that this procedure is exclusively for notifying TOO "GSL Aiti" and its affiliates that your copyrighted material has been infringed.(hereinafter “Feedback”).
                You hereby agree that all Feedback is and shall be entirely voluntary and (i) shall be deemed a derivative work based on TOO "GSL Aiti"’s proprietary materials, (ii) shall be owned by TOO "GSL Aiti",
                and (iii) absent separate agreement, shall not create any confidentiality obligation for TOO "GSL Aiti". However, TOO "GSL Aiti" shall not disclose the source of any Feedback without yours consent.
                Except as otherwise provided herein, TOO "GSL Aiti" shall be free use such Feedback as it sees fit, entirely without obligation of any kind.
            </p>
            <ul class="text-block__list">
                <li class="text-block__item">
                    <p class="text-block__text">
                        An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest;
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        A description of the copyrighted work that you claim has been infringed upon;
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        A description of where the material that you claim is infringing is located on the Site, including the auction ID number, if applicable;
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        Your address, telephone number, and e-mail address;
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        A statement by you that you have a good-faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.
                    </p>
                </li>
            </ul>
            <p class="text-block__text text-block__text--double-margin">
                TOO "GSL Aiti"’s Copyright Agent for notice of claims of copyright infringement on its Site can be reached as follows: Address: 4A Microraion 8, Auezovskiy district, Almaty, Kazakhstan, 055552
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Contact at: <a href="mailto:info@gslgames.com">info@gslgames.com</a>
            </p>
            <p class="text-block__text text-block__text--quarter-margin text-block__text--bold  grad-text">
                Software
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Certain Software of TOO "GSL Aiti" and its licensors made available for downloading in connection with this Site (the “Software”) is licensed subject to the terms of the applicable
                license agreements(“End User License Agreement”). Both the Software and any accompanying documentation made available to you is the copyrighted work of TOO "GSL Aiti" or its licensors,
                as applicable. Except as set forth in the applicable End User License Agreement, the Software is made available for use by you only and any further copying, reproduction or redistribution of
                the Software is expressly prohibited. You must agree to the terms of the applicable End User License Agreement to download or obtain Software.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                You may not use, copy, republish, frame, emulate, clone, download, transmit, rent, lease, loan, sell, assign, modify, distribute, license, sublicense, decompile, disassemble, create a
                derivative work or otherwise reverse engineer, or transfer the licensed program, or any subset of the Site, Software or Service, except as expressly authorized herein or in the applicable
                license agreement. Any such unauthorized use shall result in immediate and automatic termination of your license to use this Site and, as applicable, of the license for TOO "GSL Aiti"’s
                Software and Service and may result in criminal and/or civil prosecution.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                WARRANTIES, IF ANY, WITH RESPECT TO SUCH SOFTWARE, PRODUCTS AND SERVICE SHALL ONLY APPLY AS EXPRESSLY SET FORTH IN THE APPLICABLE LICENSE AGREEMENT. TOO "GSL AITI" HEREBY EXPRESSLY DISCLAIMS ALL
                FURTHER REPRESENTATIONS AND WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS FOR ANY PARTICULAR PURPOSE OR NON-INFRINGEMENT WITH RESPECT TO THE SOFTWARE.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Without limiting the foregoing, TOO "GSL Aiti" makes no warranty that: the Service and products will meet your requirements; the Service and products will be uninterrupted, timely, secure,
                or error-free; the results that may be obtained from the use of the Service or materials will be effective, accurate, or reliable; the quality of any Service or products purchased or accessible
                by you through the Site will meet your expectations; any errors in the software obtained from or used through the Site, or any defects in the Site, its Service or products, will be corrected.
            </p>
            <p class="text-block__text text-block__text--quarter-margin text-block__text--bold  grad-text">
                Unlawful or Prohibited Use
            </p>
            <p class="text-block__text text-block__text--double-margin">
                You may not use this Site for any purpose that is unlawful, prohibited by these Terms of Use, or in any way interferes or attempts to interfere with the proper working of this Site.
                You may not use this Site in any manner that could damage, disable, overburden, or impair this Site and/or Service, or that interferes with any other party's use and enjoyment of this Site.
                You may not obtain or attempt to obtain any materials or information through any means not intentionally made available by TOO "GSL Aiti" to all users of this Site.
                You agree that you will not use any third-party software that intercepts, “mines,” or otherwise collects information from or through the Site and/or Service.
                You may not obtain or attempt to obtain any materials or information through any means not intentionally made available by TOO "GSL Aiti" to all users of this Site and/or Service.
                You shall not institute, assist, or become involved in an attack upon any TOO "GSL Aiti" server or otherwise attempt to disrupt the TOO "GSL Aiti" servers.
                ANY ATTEMPT BY YOU TO DAMAGE TOO "GSL AITI" SERVERS OR UNDERMINE THE LEGITIMATE OPERATION OF TOO "GSL AITI" IS A VIOLATION OF CRIMINAL AND CIVIL LAWS AND, SHOULD SUCH AN ATTEMPT BE
                MADE OR ASSISTANCE FOR SUCH AN ATTACK BE PROVIDED, TOO "GSL AITI" RESERVES THE RIGHT TO SEEK DAMAGES FROM ANY SUCH USER TO THE FULLEST EXTENT PERMITTED BY LAW.
            </p>
            <p class="text-block__text text-block__text--quarter-margin text-block__text--bold  grad-text">
                Indemnification
            </p>
            <p class="text-block__text text-block__text--double-margin">
                You hereby agree to indemnify and hold harmless TOO "GSL Aiti", its affiliates, officers, directors, agents, and employees, from any expense, loss, claim, damage, fine, penalty or liability,
                including reasonable fees for attorneys and other professionals, payable under any judgment, verdict, court order or settlement, to the extent resulting from any claim, demand, action, suit,
                arbitration, or other proceeding initiated by any third party, including the assessment, claim or demand by a governmental agency or entity, arising out of your breach of these Terms of Use,
                including without limitation infringement by User Materials of any third-party intellectual property and/or proprietary right, including, but not limited to, patent, trademark, copyright, trade secret,
                publicity and/or privacy.
            </p>
            <p class="text-block__text text-block__text--quarter-margin text-block__text--bold  grad-text">
                Linking to the Site
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Linking to the Site is permitted provided that you comply with these rules. You may link to the home page of the Site or to any other page of this Site.
                However you are not allowed to use in-line linking or framing. You must not imply that TOO "GSL Aiti" endorses or sponsors the linker or its Site, products or Service.
                You must not use TOO "GSL Aiti"'s intellectual property including but not limited to trademarks, trade name, copyright without permission from TOO "GSL Aiti".
                Furthermore, you agree to remove the link at any time upon our request.
            </p>
            <p class="text-block__text text-block__text--quarter-margin text-block__text--bold  grad-text">
                Assignment
            </p>
            <p class="text-block__text text-block__text--double-margin">
                TOO "GSL Aiti" may assign, transfer or delegate these Terms of Use or the fulfilment of any of its obligations pursuant to these Terms of Use and/or the TOO "GSL Aiti" Privacy Policy,
                in whole or in part, to any person or entity at any time with or without your consent. You may not assign, transfer or delegate any rights or obligations under the Terms of Use or
                Privacy Policy without TOO "GSL Aiti"’s prior written consent, which may be withheld in its sole discretion, and any unauthorised assignment and delegation by you is void and ineffective.
            </p>
            <p class="text-block__text text-block__text--quarter-margin text-block__text--bold  grad-text">
                Applicable Law
            </p>
            <p class="text-block__text text-block__text--double-margin">
                By using TOO "GSL Aiti"’s Service you agree that these Terms of Use and any other agreement relating to TOO "GSL Aiti"’s Services, Software, games, or other products shall be
                governed by and construed and enforced in accordance with the laws of Kazakhstan.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                TOO "GSL Aiti" does not bear any responsibility nor assumes any risks if by any reason a product or a Service made available on this Site breaches national law of any state.
                Those who access this Site do so on their own initiative and are responsible for compliance with their national laws.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Questions regarding the Site or these Terms of Use may be submitted to: <a href="mailto:info@gslgames.com">info@gslgames.com</a>.
                Please also have a look at our <a href="gslgames.com/privacy-policy/">Online Privacy Policy</a>.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Copyright © 2024, TOO "GSL Aiti" and its licensors. All rights reserved. This Site and accompanying games and materials are the copyrighted property of TOO "GSL Aiti" and/or
                its licensors and protected by copyright laws and international intellectual property treaties. TOO "GSL Aiti" and related logo, and all related Software and Service names,
                design marks and slogans are the trademarks and/or registered trademarks of TOO "GSL Aiti" or its affiliates. All other products and service marks contained herein are the trademarks of
                their respective owners. Any use of the TOO "GSL Aiti" or third party trademarks or logos without the prior written consent of TOO "GSL Aiti" or the applicable trademark owner is strictly prohibited.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                © 2024  TOO "GSL Aiti" All Rights Reserved. <a href="gslgames.com/privacy-policy/">Privacy Policy</a>
            </p>
        </section>
    </main>
</template>

<script>
import LayoutHeader from '@/app/common/pkg/LayoutHeader.vue'

export default {
    components: {LayoutHeader}
}
</script>

<style lang="scss">
@import "~@/app/styles/text-block.scss";
@import "~@/app/styles/main-content.scss";
</style>
