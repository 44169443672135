<template>
    <LayoutHeaderMainPage />
    <main class="main-page-content">
            <div class="row gx-5 align-items-center">
                <div class="col">
                    <h2>Mobile Publishing</h2>
                    <p>You create high-quality IAP and IAA games for mobile platforms (hypercasual, hybrid-casual and casual titles).</p>
                    <p>We take care of everything else: analytics, marketing, setting up ad monetization, ASO optimization, testing, and scaling.</p>
                </div>
                <div class="col text-end">
                    <img src="~@/assets/img/gsl-games-illustration.svg" alt="GSL Games">
                </div>
            </div>
    </main>
</template>

<script>
import LayoutHeaderMainPage from '@/app/common/pkg/LayoutHeaderMainPage.vue'

export default {
    components: {LayoutHeaderMainPage}
}
</script>

<style lang="scss">
@import "~@/app/styles/main-page-content.scss";
</style>
