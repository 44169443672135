<template>
    <header class="header">
        <nav class="header__navigation">
            <ul class="header__nav-list">
                <li class="header__nav-item header__nav-item--logo">
                    <router-link class="header__logo-link" to="/">
                        <img class="header__logo" src="~@/assets/img/logo.svg" alt="GSLGames" width="225">
                    </router-link>
                </li>
            </ul>
        </nav>
    </header>
</template>

<script>
export default {
}
</script>

<style lang="scss">
@import "../../styles/header";
</style>
